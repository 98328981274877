<template>
  <div>
    <b-checkbox
      v-for="(choice, cIndex) in field.properties.basic.choices"
      :key="cIndex"
      :value="values[field.id] ? values[field.id].split(',') : null"
      class="is-12"
      :class="{
        'column': !field.properties.basic.displayOptionsInRow
      }"
      size="is-small"
      :name="field.id"
      :true-value="choice"
      :native-value="choice"
      @input="$emit('update', {
        value: choice
      })"
    >
      {{ choice }}
    </b-checkbox>
  </div>
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  return {};
};
export default __sfc_main;
</script>
